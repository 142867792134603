@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

header{
  font-family: Poppins;
}

body{
  font-family: Open Sans;
}

.label{
  font-family: Open Sans;
}

.display {
  font-family: 'Poppins', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
}

a{
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-decoration: none !important;
}

.App {
  text-align: center;
}

